import * as CheckoutActions from "@actions/checkout.actions";
import * as ShippingActions from "@actions/shipping.actions";
import { STATUS_NAMES } from "@constants/constants";
import { processLogisticUnits } from "@utils/jsonUtils";

const defaultState = {
	logisticUnits: [],
	totalOfferCost: 0,
	totalShippingCost: 0,
	isValid: true,
	isHidden: false,
	alerts: [],
	isSectionDisabled: false,
	porterBuddyInstructions: "",
	totalOfferCostNet: 0,
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case CheckoutActions.CHECKOUT_FETCH_DONE:
			return {
				...state,
				...processLogisticUnits(action.json.order, state),
				isHidden:
					!action.json.order ||
					action.json.status === STATUS_NAMES.EXPIRED ||
					action.json.status === STATUS_NAMES.PLACED,
			};

		case ShippingActions.SHIPPING_SELECT_REQUESTED: {
			const logisticUnit = state.logisticUnits.find(
				(logisticUnit) => logisticUnit.id === action.payload.logisticUnitId,
			);

			if (!logisticUnit) {
				return { ...state };
			}

			const currentMethod = logisticUnit.shipping.availableShipping.find(
				(shipping) => shipping.typeCode === action.payload.methodId,
			);
			const currentOption = currentMethod.options.find(
				(option) => option.id === action.payload.optionId,
			);

			return {
				...state,
				logisticUnits: state.logisticUnits.map((logisticUnit) =>
					logisticUnit.id === action.payload.logisticUnitId
						? {
								...logisticUnit,
								shipping: {
									...logisticUnit.shipping,
									currentMethod,
									currentOption,
									extraServiceIds: action.payload.validatedExtraServiceIds,
								},
						  }
						: { ...logisticUnit },
				),
			};
		}

		case ShippingActions.DISABLE_SECTION:
			return {
				...state,
				isSectionDisabled: true,
			};

		case ShippingActions.ENABLE_SECTION:
			return {
				...state,
				isSectionDisabled: false,
			};

		case ShippingActions.SET_PORTER_INSTRUCTIONS:
			return {
				...state,
				porterBuddyInstructions: action.payload.instructions,
			};

		// Debugging guide: https://komplett.atlassian.net/wiki/spaces/QA/pages/4724064262/Mock+shipping+state
		case "__DEBUG_SHIPPING": {
			if (!window.allowDebug) {
				return state;
			}

			const mockData = JSON.parse(localStorage.getItem("DEBUG_SHIPPING"))[
				action.payload[0] !== 0 ? action.payload[0] : 0
			];

			const requestedShippingOptions = mockData.availableShipping.find(
				(x) => x.typeCode === mockData.selectedShipping.typeCode,
			);

			return {
				...state,
				...processLogisticUnits(
					{
						logisticUnits: [
							{
								id: state.logisticUnits[0].id,
								offers: state.logisticUnits[0].offers,
								quantity: 1,
								shipping: {
									...mockData,
									...(action.payload[1] !== 0
										? {
												selectedShipping: {
													extraServiceIds:
														requestedShippingOptions.extraServiceIds,
													extraServicesTotalGross:
														requestedShippingOptions.extraServicesTotalGross,
													shippingOptionId:
														requestedShippingOptions.options[action.payload[1]]
															.id,
													typeCode: requestedShippingOptions.typeCode,
												},
										  }
										: {}),
								},
							},
						],
					},
					state,
				),
			};
		}

		default:
			return state;
	}
};
