import PorterInstructions from "@components/order/shipping/PorterInstructions";
import ShippingExtraServices from "@components/order/shipping/ShippingExtraServices";
import ShippingMethodAlternatives from "@components/order/shipping/ShippingMethodAlternatives";
import ShippingCarryin from "@components/order/shipping/carryin/ShippingCarryin";
import { PORTER_BUDDY_CHAR_LIMIT, TYPECODES } from "@constants/constants";
import React from "react";

const ShippingMethodContent = ({
	currentMethod,
	currentOption,
	extraServiceIds,
	handleSelectOption,
	handleShippingClick,
	isSectionDisabled,
	porterBuddyInstructions,
	formatMessage,
	logisticUnitId,
	shipping,
}) => {
	const isCarryin = shipping.typeCode === TYPECODES.CARRYIN;
	const hasAlternatives = currentMethod.hasAlternatives;
	const hasExtraServices = !!currentOption.extraServices.length;
	const hasDescription = currentOption && currentOption.description;
	const isPorter = currentMethod.typeCode === TYPECODES.PORTER;
	const hasEstimatedDeliveryDate =
		currentOption &&
		currentOption.estimatedDeliveryDate &&
		!currentMethod.hasAlternatives;

	if (isCarryin) {
		return <ShippingCarryin />;
	}

	return (
		<>
			{hasAlternatives && (
				<ShippingMethodAlternatives
					currentMethod={currentMethod}
					currentOption={currentOption}
					handleSelectOption={handleSelectOption}
					isDiabled={isSectionDisabled}
					logisticUnitId={logisticUnitId}
					typeCode={shipping.typeCode}
				/>
			)}
			{hasExtraServices && (
				<ShippingExtraServices
					extraServices={currentOption.extraServices}
					extraServiceIds={extraServiceIds}
					onExtraServiceCheck={handleShippingClick}
					isDisabled={isSectionDisabled}
				/>
			)}
			{hasDescription && (
				<p
					className="extra-message"
					// todo: Let's try to remove all usages dangerouslySetInnerHTML
					dangerouslySetInnerHTML={{ __html: currentOption.description }}
				/>
			)}
			{isPorter && (
				<PorterInstructions
					instructionsValue={porterBuddyInstructions}
					characterLimit={PORTER_BUDDY_CHAR_LIMIT}
					placeholder={formatMessage({
						id: "porterbuddy.delivery.placeholder",
					})}
				/>
			)}
			{hasEstimatedDeliveryDate && (
				<span
					className="estimated-delivery"
					// todo: Let's try to remove all usages dangerouslySetInnerHTML
					dangerouslySetInnerHTML={{
						__html: currentOption.estimatedDeliveryDate,
					}}
				/>
			)}
		</>
	);
};

export default ShippingMethodContent;
