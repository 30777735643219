import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const RadioLabel = styled.label(
	({ isChecked, isDisabled, theme }) => css`
		display: grid;
		grid-template-columns: 24px 1fr;
		grid-gap: 16px;
		align-items: center;
		font-weight: ${isChecked ? "bold" : "regular"};
		cursor: ${isDisabled ? "not-allowed" : "pointer"};
		color: ${isDisabled ? theme.colors.primaryGray : "inherit"};
	`,
);

export const RadioInput = styled.input(
	({
		theme: {
			colors: { radioButton },
		},
		disabled,
	}) => css`
		margin: 0;
		padding: 0;
		/* Add if not using autoprefixer */
		-webkit-appearance: none;
		/* Remove most all native input styles */
		appearance: none;
		/* For iOS < 15 */
		background-color: ${radioButton.background};
		width: 21px;
		height: 21px;
		border: 1px solid ${radioButton.outerBorder};
		border-radius: 50%;
		transform: translateY(-0.075em);

		display: grid;
		place-content: center;

		cursor: ${disabled ? "not-allowed" : "pointer"};

		&::before {
			content: "";
			width: 13px;
			height: 13px;
			border-radius: 50%;
			transform: scale(0);
			transition: 120ms transform ease-in-out;
			box-shadow: inset 1px 1px
				${disabled ? radioButton.disabled : radioButton.innerCircle};
			/* Windows High Contrast Mode */
			background-color: ${disabled
				? radioButton.disabled
				: radioButton.innerCircle};
		}

		&:checked,
		&:hover {
			border-color: ${radioButton.outerBorderSelected};
		}

		&:disabled {
			border-color: ${radioButton.disabled};
		}

		&:checked::before {
			transform: scale(1);
		}
	`,
);
