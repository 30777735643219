const colors = {
	primary: "#F8B912",
	secondary: "#FECC41",
	tertiary: "#EBB000",
	primaryLight: "#F7DB8D",
	primaryShadow: "#C08B00",

	primaryWhite: "#fff",

	primaryBlack: "#333",

	primaryGray: "#CECECE",
	secondaryGray: "#EFEFEF",
	tertiaryGray: "#F5F5F5",

	primaryRed: "#D0021B",
	secondaryRed: "#ffdedd",

	primaryBlue: "#0670A3",
};

const button = {
	primary: {
		color: colors.primaryBlack,
		background: colors.primary,
		shadow: colors.primaryShadow,

		activeBackground: colors.tertiary,
		hoverBackground: colors.secondary,

		disabledBackground: colors.primaryLight,
	},
	primaryText: {
		color: colors.primaryBlue,
	},
	secondary: {
		color: colors.primaryBlack,
		border: colors.primaryGray,
		borderActive: colors.primaryBlack,
		borderHover: colors.primaryBlack,

		disabled: colors.primaryGray,
		disabledBorder: colors.tertiaryGray,
	},
};

const radioButton = {
	background: colors.primaryWhite,
	outerBorder: colors.primaryGray,
	outerBorderSelected: colors.primaryBlack,
	innerCircle: colors.primary,
	disabled: colors.primaryGray,
};

const section = {
	background: colors.primaryWhite,
	border: colors.primaryGray,
	header: {
		background: colors.primary,
		border: colors.primaryGray,
	},
};

const collapsiblePanel = {
	background: colors.primaryWhite,
};

const panel = {
	border: colors.primaryGray,
};

export const theme = {
	breakpoints: {
		sm: "415px",
		md: "919px",
		lg: "1280px",
	},
	colors: {
		...colors,
		button,
		radioButton,
		section,
		panel,
		collapsiblePanel,
		divider: colors.primaryGray,
		backgroundMobileContent: "#f0f0f0",
		backgroundRecipientIcon: "#fff",
		textMobilePrimary: "#222222",
		backgroundRecipientSection: "#6d8b97",
		textSecondary: "#fff",
		headerBackground: "#476470",
		linkColor: "#0670A3",
		linkHoverColor: "#222",
	},
	extraColors: {
		boulder: "#767676",
	},
	icons: {
		checkmark3: "M14 2.5l-8.5 8.5-3.5-3.5-1.5 1.5 5 5 10-10z",
	},
};
