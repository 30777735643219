import ShippingItemDropdown from "@components/order/shipping/ShippingItemDropdown";
import ShippingItemRadioSet from "@components/order/shipping/ShippingItemRadioSet";
import { DISABLE_DROPDOWN_OPTIONS_LIMIT } from "@constants/constants";
import React from "react";

const ShippingMethodAlternatives = ({
	currentMethod,
	currentOption,
	handleSelectOption,
	isDisabled,
	logisticUnitId,
	typeCode,
}) => {
	const shouldDisplayRadioSet =
		currentMethod.options.length < DISABLE_DROPDOWN_OPTIONS_LIMIT;

	if (shouldDisplayRadioSet) {
		return (
			<ShippingItemRadioSet
				options={currentMethod.options}
				hasDifferentPrices={currentMethod.hasDifferentPrices}
				hasDifferentNames={currentMethod.hasDifferentNames}
				hasDifferentTransporters={currentMethod.hasDifferentTransporters}
				isDisabled={isDisabled}
				handleSelectOption={handleSelectOption}
				typeCode={typeCode}
				currentOption={currentOption}
			/>
		);
	}

	return (
		<ShippingItemDropdown
			id={`shippingOptions-${logisticUnitId}`}
			options={currentMethod.options}
			hasDifferentPrices={currentMethod.hasDifferentPrices}
			hasDifferentNames={currentMethod.hasDifferentNames}
			hasDifferentTransporters={currentMethod.hasDifferentTransporters}
			handleSelectOption={handleSelectOption}
			typeCode={typeCode}
			currentOption={currentOption}
			isDisabled={isDisabled}
		/>
	);
};

export default ShippingMethodAlternatives;
