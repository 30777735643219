import classNames from "classnames";
import { useSelect } from "downshift";
import React, { useRef } from "react";

const Dropdown = ({
	id,
	className,
	options,
	selectedId,
	onSelect,
	renderOption,
	isDisabled,
}) => {
	const toggleButtonRef = useRef(null);
	const optionRefs = useRef([]);

	const {
		isOpen,
		selectedItem,
		getToggleButtonProps,
		highlightedIndex,
		getMenuProps,
		getItemProps,
	} = useSelect({
		items: options,
		selectedItem: options.find((option) => option.id === selectedId),
		onSelectedItemChange: ({ selectedItem }) => {
			onSelect(selectedItem);
		},
		onStateChange: ({ type, highlightedIndex }) => {
			switch (type) {
				case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowDown:
				case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowUp:
				case useSelect.stateChangeTypes.ToggleButtonKeyDownPageDown:
				case useSelect.stateChangeTypes.ToggleButtonKeyDownPageUp:
					if (typeof highlightedIndex === "number") {
						optionRefs.current[highlightedIndex]?.focus();
					}
					break;
				case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
				case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
				case useSelect.stateChangeTypes.ItemClick:
					if (toggleButtonRef.current) {
						toggleButtonRef.current.focus();
						toggleButtonRef.current.scrollIntoView({
							behavior: "smooth",
							block: "nearest",
						});
					}
					break;
				default:
					break;
			}
		},
	});

	const selectedOption = options.find(
		(option) => option.id === selectedItem?.id,
	);

	return (
		<div
			id={id}
			className={classNames("dropdown", className, { disabled: isDisabled })}
		>
			<div
				className={classNames("drop-button option", {
					active: isOpen,
					disabled: isDisabled,
				})}
				{...getToggleButtonProps({
					ref: toggleButtonRef,
					disabled: isDisabled,
				})}
			>
				<span>{renderOption(selectedOption)}</span>
			</div>
			<ul
				aria-hidden={!isOpen}
				className={classNames("options", { hidden: !isOpen, active: isOpen })}
				{...getMenuProps()}
			>
				{options.map((option, index) => {
					return (
						<li
							className={classNames("option", {
								highlighted: highlightedIndex === index,
							})}
							{...getItemProps({ item: option, index })}
							key={option.id + index}
							ref={(optionHTMLElement) => {
								!optionRefs.current[index] &&
									optionRefs.current.push(optionHTMLElement);
							}}
						>
							{renderOption({ ...option })}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default Dropdown;
