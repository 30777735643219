import Dropdown from "@components/form/Dropdown";
import ShippingOptionLabel from "@components/order/shipping/ShippingOptionLabel";
import React from "react";

const ShippingItemDropdown = ({
	id,
	options,
	handleSelectOption,
	typeCode,
	currentOption,
	hasDifferentPrices,
	hasDifferentNames,
	hasDifferentTransporters,
	isDisabled,
}) => {
	return (
		<Dropdown
			id={id}
			className="available-items"
			selectedId={currentOption.id}
			onSelect={(selectedItem) => handleSelectOption(typeCode)(selectedItem.id)}
			isDisabled={isDisabled}
			options={options}
			renderOption={(option) => (
				<ShippingOptionLabel
					typeCode={typeCode}
					header={option.header}
					subHeader={option.subHeader}
					label={option.label}
					hasDifferentNames={hasDifferentNames}
					hours={option.hours}
					transporter={option.transporter}
					hasDifferentTransporters={hasDifferentTransporters}
					street={option.street}
					postCode={option.postCode}
					price={option.price}
					hasDifferentPrices={hasDifferentPrices}
					estimatedDeliveryDate={option.estimatedDeliveryDate}
					estimatedDeliveryTime={option.estimatedDeliveryTime}
					greenDeliveryLabel={option.greenDeliveryLabel}
				/>
			)}
		/>
	);
};

export default ShippingItemDropdown;
